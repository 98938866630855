import service from '@/middleware'

const pointOfInterestApi = {
  async getCategories() {
    let result = await service.http.get('/api/point-of-interest/categories')
    return result
  },

  async insert(poi) {
    let result = await service.http.post('/api/point-of-interest', poi)
    return result
  },

  async getAllPointOfInterest() {
    let result = await service.http.get('/api/map-pois');
    return result.data;
  },

  async getAllByInterprise() {
    let result = await service.http.get('/api/point-of-interest/allByEnterprise');
    return result.data;
  }
}

export default pointOfInterestApi
