import service from '@/middleware';


const ZonesApi = {
    async getZonesByEnterprise() {
        const response = await service.http.get('/api/zones/byEnterprise')
        return response.data;
    }
}

export default ZonesApi