import selectorService from '@/business/selectorService';
import AutocompleteComponent from '@/components/commons/autocomplete/AutocompleteComponent.vue';
import SelectorComponent from '@/components/commons/selector/SelectorComponent.vue';
import i18n from '@/i18n';
import { CustomTimeRangeTypes, TimeRanges } from '@colven/common-domain-lib/lib';
import { TileLayer } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import moment from 'moment';
import filterService from '../../business/filter.service';
import mapHistoryService from '../../business/mapHistoryService';
import mapService from '../../business/mapService';
import { HOUR_SELECTOR } from './constants';
import stateService from '../../business/state.service';

export default {
    name: 'MapHistoryComponent',
    components: {
        SelectorComponent,
        AutocompleteComponent
    },
    props: {},
    data() {
        return {
            mapId: 'mapHistoryComponent',
            mapInstance: null,
            loadingSpinner: false,
            markersMap: {},
            tracksTime: {},
            thingItems: [],
            thingSelected: null,
            driveUnitsMakerMap: new Map(),
            lastUpdateDate: null,
            updateInterval: null,
            slider: {
                min: 0,
                max: 10
            },
            timestampValue: 0,
            hourSelected: '00',
            hourSelector: HOUR_SELECTOR,
            playInterval: null,
            driveUnitSwitch: {
                id: "showDriveUnitsSwitch",
                show: false,
                name: i18n.t('trackingMap.filters.showDriveUnits'),
                textTrue: '',
                textFalse: '',
                value: false
            },
            timeSort: {
                id: "showTimeSelectorSwitch",
                show: false,
                name: i18n.t('mapHistory.selector.time.title'),
                textTrue: i18n.t('mapHistory.selector.time.communicationTimestamp'),
                textFalse: i18n.t('mapHistory.selector.time.trackTimestamp'),
                value: true
            },
            configBase: {
                idElement: 'mapHistoryComponent',
                centerPosition: [-24.475390108274823, -65.03700482239184],
                zoom: 8,
                preferCanvas: false,
                zoomControl: true
            },
            drawer: true,
            selectorModel: selectorService.getSelectorModel(false),
            filters: null
        }
    },
    beforeDestroy() {
    },
    computed: {
        currentTime() {
            return (this.timestampValue ? moment(this.timestampValue * 1000).format("DD/MM/YYYY HH:mm:ss") : "");
        }
    },
    async mounted() {
        this.loadingSpinner = true;
        try {
            await mapService.getUpdatedIcons();
            this.initSelector();
            await this.getThingsSelector();
            this.mapInstance = mapService.createMap(this.configBase);
            this.selectorModel.switches.push(this.driveUnitSwitch);
            this.selectorModel.switches.push(this.timeSort);
        } finally {
            this.loadingSpinner = false;
        }
    },
    onDestroy() {
        clearInterval(this.updateInterval);
    },
    methods: {
        initSelector() {
            this.selectorModel.hideAutoReport = true;
            this.selectorModel.showTimeFormatSelector = false;
            this.selectorModel.filterSwitch.value = true;
            this.selectorModel.showGroupEquipmentFilter = true;
            this.selectorModel.hideEquipmentConfigSwitch = true;
            this.selectorModel.hideEquipmentConfigSwitch = true;
            this.selectorModel.disableGetReportButton = false;
            this.selectorModel.selectedCount = 0;
            this.selectorModel.selectionLimit = 60;
            this.selectorModel.selectedDateAndTimeRange = TimeRanges.CUSTOM;
            this.selectorModel.singleDaySelect = true;
            this.selectorModel.selectedDateAndTimeRangeCustomType = CustomTimeRangeTypes.DATE;
            this.selectorModel.numericInputs.cleanAndUpdate([{
                show: false, label: "mapHistory.selector.radius.label", id: "storeRoomRadius", value: 1500, title: "mapHistory.selector.radius.title"
            }])
        },
        beforeDestroy() {
            clearInterval(this.playInterval);
        },
        async changeLinetime(value) {
            await mapHistoryService.processMomentTracks(this.tracksTime, value, this.markersMap);
        },
        async getThingsSelector() {
            const thingsByGroup = await filterService.getThingsByGroup();
            this.selectorModel.groupEquipmentFilter.data.cleanAndUpdate(thingsByGroup);
        },
        async playHistory() {
            if (!this.playInterval) {
                this.playInterval = setInterval(() => {
                    this.changeLinetime(++this.timestampValue);
                    if (this.slider.max === this.timestampValue) {
                        this.pauseHistory();
                    }
                }, 500);
            }
        },
        async pauseHistory() {
            clearInterval(this.playInterval);
            this.playInterval = null;
        },
        centerMap(event) {
            if (event && event.id) {
                mapHistoryService.centerMap(event.id, this.markersMap, 15, this.mapInstance);
            }
        },
        async hourChanged(hour) {
            this.pauseHistory();
            const daySelected = moment(this.filters.sinceDate, 'YYYY-MM-DD').unix();
            this.slider.min = daySelected + (parseInt(hour) * 3600);
            this.slider.max = this.slider.min + 3599;
            this.timestampValue = this.slider.min;
            await this.changeLinetime(this.timestampValue);
        },

        async cleanMap() {
            this.pauseHistory();
            this.mapInstance.eachLayer((layer) => {
                if (!(layer instanceof TileLayer)) {
                    this.mapInstance.removeLayer(layer);
                }
            });
            this.markersMap = {};
            this.tracksTime = {};
            this.thingItems = [];
            this.thingSelected = null;
            this.driveUnitsMakerMap = new Map();
            this.lastUpdateDate = null;
            this.updateInterval = null;
            this.slider = {
                min: 0,
                max: 10
            };
            this.timestampValue = 0;
            this.hourSelected = '00';
            this.hourSelector = HOUR_SELECTOR;
            this.playInterval = null;
        },
        async getReport(filters) {
            this.loadingSpinner = true;
            try {
                this.drawer = false;
                this.cleanMap();
                this.filters = filters;
                const communicationDateSort = this.timeSort.value
                const thingsSelected = filters.groupEquipmentFilterSelected;
                const daySelected = moment(filters.sinceDate, 'YYYY-MM-DD').unix();
                const thingIds = filterService.getThingsDataByGroupsSelected(thingsSelected);
                const statesMap = await stateService.getStatesNameMapByThingsId(
                    thingIds.map((thingSelected) => thingSelected.idThing), 'id'
                );
                const storeRoomRadiusObject = filters.numericInputs.find((numericInput) => { return numericInput.id === 'storeRoomRadius' });
                const storeRoomRadiusValue = storeRoomRadiusObject ? storeRoomRadiusObject.value : 1500;
                this.thingItems = thingIds.map((thingSelected) => { return { name: thingSelected.name, id: thingSelected.idThing } });
                const tracks = await mapHistoryService.getTrackData(thingIds, daySelected);
                this.slider.min = daySelected;
                this.slider.max = daySelected + 86399;//23:59:59
                const { linetimeMap, markersMap } = await mapHistoryService.getLineTimeMap(tracks, this.mapInstance, daySelected, storeRoomRadiusValue, statesMap, communicationDateSort);
                this.markersMap = markersMap;
                this.tracksTime = linetimeMap;
                if (this.driveUnitSwitch.value && Object.values(this.driveUnitsMakerMap).length === 0) {
                    const driveUnits = await mapService.getDriveUnitsByEnteprise();
                    this.driveUnitsMakerMap = mapService.addPolygons(this.mapInstance, this.driveUnitsMakerMap, driveUnits);
                }
            } finally {
                this.loadingSpinner = false;
            }
        }
    }
}

