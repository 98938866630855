import activityApi from '@/api/activity.api';
import sectorApi from '@/api/sector.api';
import stateApi from '@/api/state.api';
import pointOfInterestApi from "../api/pointOfInterest.api";
import thingApi from "../api/thing.api";
import categoryApi from '../api/category.api';

class FilterService {

    async getAllHarvestFront() {
        const { data } = await thingApi.getHarvestFrontsByEnterprise();
        return data.map(harvestFront => { return { id: harvestFront._id, name: harvestFront.name } });
    }

    async getAllCategoriesByEnterprise() {
        const data = await categoryApi.getAllByEnterprise();
        return data.map(category => { return { id: category._id, name: category.name } });
    }

    async getAllCategoriesMapByEnterprise() {
        const data = await categoryApi.getAllByEnterprise();
        const result = new Map();

        for (const pointOfInterest of data) {
            result.set(pointOfInterest._id, pointOfInterest);
        }

        return result;
    }

    async getAllStatesToSelector() {
        const { data } = await stateApi.getStatesFilterStructure();
        return data.map(state => { return { id: state.key, name: state.name } });
    }

    async getPointOfInterestCategories() {
        const { data } = await pointOfInterestApi.getCategories();
        return data.map(category => { return { id: category._id, name: category.name } });
    }

    async getThingsByGroup() {
        const { data } = await thingApi.getNested();
        return data;
    }

    async getActivitiesToSelector() {
        const { data } = await activityApi.getAll();
        data.push({
            "id": "5f88a3dad885d611b835c6b1",
            "name": "Sin actividad",
            "key": "NO_ACTIVITY",
        })
        return data;
    }

    async getAllThingTypesToSelector() {
        const types = await thingApi.getAllTypesToSelector();
        const lang = localStorage.getItem('locale');
        return types.map((type) => { return { name: type.name[lang], id: type.key } })
    }

    async getSectorToSelector() {
        const { data } = await sectorApi.getAll();
        data.push({
            "id": "5f89a20ac93863159a8a97c0",
            "name": "Sin sector",
            "key": "NO_SECTOR",
        });
        return data;
    }

    getSelectorMapById(selector, selectorMap) {
        if (selector && selector.length > 0) {
            for (const element of selector) {
                selectorMap.set(element.id, true);
            }
        }
    }

    getStateMapSelectedByKey(states, statesMap) {
        if (states && states.length > 0) {
            for (const state of states) {
                statesMap.set(state.id, true);
            }
        }
    }

    getThingsMapSelectedById(nodeGroup, thingsMap) {
        if (nodeGroup && nodeGroup.length > 0) {
            for (const node of nodeGroup) {
                if (node) {
                    if (node.nodeType === 'THING') {
                        thingsMap.set(node.id, true);
                    } else {
                        this.getThingsMapSelectedById(node.children, thingsMap);
                    }
                }
            }
        }
    }

    getThingsDataByGroupsSelected(nodeGroup) {
        if (nodeGroup && nodeGroup.length > 0) {
            let result = [];
            for (const node of nodeGroup) {
                if (node) {
                    if (node.nodeType === 'THING') {
                        result.push({
                            idThing: node.id,
                            typeId: node.typeId,
                            type: node.typeKey,
                            name: node.name
                        });
                    } else {
                        const elements = this.getThingsDataByGroupsSelected(node.children);
                        result.pushArray(elements);
                    }
                }
            }
            return result;
        }
        return [];
    }
}

export default new FilterService();