import service from '@/middleware';

class CategoryApi {

    async getAllByEnterprise() {
        const result = await service.http.get("/api/category/byEnterprise");
        return result.data;
    }

}

export default new CategoryApi();