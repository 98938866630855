import service from '@/middleware'

const driveUnitApi = {
  async getStructureByEnterprise() {
    const result = await service.http.get(`/api/drive-unit/enterprise-structure`)
    return result.data
  },
  async getById(id) {
    const result = await service.http.get(`/api/drive-unit/${id}`)
    return result
  },
  async getByEnterprise() {
    const result = await service.http.get(`/api/drive-unit/enterprise`)
    return result.data
  },

  async findDriveUnitsByEnteriseId() {
    const result = await service.http.get(`/api/drive-unit/findDriveUnitsByEnteriseId`)
    return result.data
  }
}

export default driveUnitApi
