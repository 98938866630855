import thingApi from '@/api/thing.api';
import { iconSkeleton } from '@/assets/icon-skeleton.';
import { MARKER_ICONS } from '@/assets/markers';
import { mapCurrentStateService } from '@/business/mapCurrentStateService';
import i18n from '@/i18n';
import indexedDBService from '@/indexedDB/indexedDB.service';
import { ReportConversionUtil } from '@colven/common-domain-lib/lib';
import leaflet from 'leaflet';
import moment from 'moment';
import mapCurrentStateApi from '../api/mapCurrentState.api';


import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import driveUnitApi from '../api/drive-unit.api';
import pointOfInterestApi from '../api/pointOfInterest.api';
import ZonesApi from '../api/zones.api';
import filterService from './filter.service';

export class MapService {

    constructor() {
        this.defaultIcon = null;
        this.defaultDriveUnitColor = '#D5D5D5';
        this.driveUnits = [];
    }

    centerMap(position, zoom, map) {
        map.setView(position, zoom);
    }

    centerMapByMarker(marker, zoom, map) {
        if (marker) {
            const latLong = marker._latlng;
            this.centerMap(latLong, zoom, map);
        }
    }

    /**
     * obtiene las unidades de manejo por empresa (Actual) y las tranforma a objectos del tipo:
     * id -> driveUnitId
     * coordinates -> poligono
     * properties -> { name -> nombre driveUnit, color -> configurado por defecto en el servicio }
     * @returns Array Poligonos
     */
    async getDriveUnitsByEnteprise() {
        if (this.driveUnits && this.driveUnits.length === 0) {
            this.driveUnits = await driveUnitApi.findDriveUnitsByEnteriseId();
        }
        return this.processDriveUnits(this.driveUnits);
    }

    /**
     * Transforma las entidades driveUnits a poligonos
     * id -> driveUnitId
     * coordinates -> poligono
     * properties -> { name -> nombre driveUnit, color -> configurado por defecto en el servicio }
     * @param Array driveUnits 
     * @returns 
     */
    processDriveUnits(driveUnits) {
        const result = [];
        if (driveUnits && driveUnits.length > 0) {
            for (const driveUnit of driveUnits) {
                if (driveUnit.geometry && driveUnit.geometry.coordinates && driveUnit.geometry.coordinates.length > 0) {
                    const coordinates = driveUnit.geometry.coordinates[0];
                    const reversedCoordinates = coordinates.map(point => [point[1], point[0]]);
                    result.push({ id: driveUnit._id, coordinates: reversedCoordinates, properties: { name: driveUnit.properties.Name, color: this.defaultDriveUnitColor } });
                }
            }
        }
        return result;
    }

    processZones(zones) {
        const result = [];
        if (zones && zones.length > 0) {
            for (const zone of zones) {
                if (zone.geometry && zone.geometry.coordinates && zone.geometry.coordinates.length > 0) {
                    const coordinates = zone.geometry.coordinates[0];
                    const reversedCoordinates = coordinates.map(point => [point[1], point[0]]);
                    result.push({ id: zone._id, coordinates: reversedCoordinates, properties: zone.properties });
                }
            }
        }
        return result;
    }

    /**
     * Se recorre la lista de poligonos y se instancian en el mapa
     * @param leafletMap mapInstance intancia de mapa a actualizar
     * @param Array[Object] poligonsMap mapa de objectos poligonos instanciados en el mapa
     * @param Array polygons lista de poligonos
     */
    addPolygons(mapInstance, poligonsMap, polygons) {
        for (const poly of polygons) {
            let polygon = this.addPolygon(mapInstance, poly.coordinates, poly.properties);
            poligonsMap.set(poly.id, polygon);
            polygon.data = poly.properties;
            polygon.data.show = true;
        }
    }

    /**
     * 
     * @param leafletMap mapInstance intancia de mapa a actualizar
     * @param Points poligono
     * @param Config Configuracion de poligono { name, color }
     * @returns 
     */
    addPolygon(mapInstance, polygonPoints, config) {
        const finalConfig = {
            color: config.borderColor ? config.borderColor : 'black',
            weight: config.borderWidth ? config.borderWidth * 10 : 0.5,
            fillColor: config.color ? config.color : 'black',
            fillOpacity: config.opacity ? config.opacity : 0.7
        };
        const polygon = leaflet.polygon(polygonPoints, finalConfig);
        polygon.bindTooltip(config.name, { direction: "auto" });
        polygon.addTo(mapInstance)
        return polygon;
    }

    createMap(config) {
        // inicializa el mapa en el div con el id proporcionado, con centro y zoom proporcionado en las coordenadas de la configuración 
        const map = leaflet.map(config.idElement, config).setView(config.centerPosition, config.zoom);
        leaflet.tileLayer("https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}&s=Ga", { maxZoom: 19 }).addTo(map);
        leaflet.control.zoom({ position: 'bottomleft' }).addTo(map);
        this.enableLatLngLog(map);
        return map;
    }

    enableLatLngLog(mapInstance) {
        if (localStorage.getItem("mapLog") && mapInstance) {
            mapInstance.on('click', function (e) {
                console.log(`"Latitud": "${e.latlng.lat}", "Longitud": "${e.latlng.lng}"`);
            });
        }
    }

    async createMarkerByTracks(map, tracks, storeRoomRadius, showLabel) {
        const markersMap = new Map();
        const markersGroup = leaflet.markerClusterGroup({
            showCoverageOnHover: false,
            maxClusterRadius: 40,
            spiderfyDistanceMultiplier: 3,
            disableClusteringAtZoom: 16,
            chunkedLoading: false
        }).addTo(map);
        if (tracks && tracks.length > 0) {
            for (const track of tracks) {
                if (!markersMap.get(track.thingId)) {
                    const marker = await this.createMarkerByTrack(track, markersGroup, map, storeRoomRadius, showLabel);
                    markersMap.set(track.thingId, marker);
                }
            }
            // map.addLayer(markersGroup);
        }
        return { markersMap, map, markersGroup };
    }

    async updateMarkersByTracks(tracks, markersMap, markerGroup, map, storeRoomRadius, filters, showLabel) {
        if (tracks && tracks.length > 0) {
            for (const track of tracks) {
                let marker = markersMap.get(track.thingId);
                let previousShow = true;
                if (marker) {
                    this.updateLatLong(track, marker);
                    if (marker.circle) {
                        marker.circle.setRadius(storeRoomRadius);
                    }
                    previousShow = marker.trackData.show;
                    marker.setTooltipContent(
                        () => { return this.getMCSPopUpByTemplate(track) }
                    );
                    const icon = await this.getIcon(track, showLabel);
                    marker.setIcon(icon);
                    marker.trackData = track;
                    marker.trackData.stateKey = marker.trackData && marker.trackData.state && marker.trackData.state.key;
                } else {
                    marker = await this.createMarkerByTrack(track, markerGroup, map, storeRoomRadius, showLabel);
                    markersMap.set(track.thingId, marker);
                }
                const show = this.showByFilters(filters, track);
                this.addShowMarker(markerGroup, map, marker, show, previousShow);
            }
        }
    }

    async updateLatLong(track, marker) {
        const lat = (track.position.coordinates[1]);
        const lng = (track.position.coordinates[0]);
        const newLatLng = new leaflet.LatLng(lat, lng);
        marker.setLatLng(newLatLng);
        if (marker.circle) {
            const colorData = this.getRadiousColor(track);
            marker.circle.options.color = colorData.color;
            marker.circle.options.fillColor = colorData.fillColor;
            marker.circle.setLatLng(newLatLng);
        }
    }

    updateCircleRadius(markers, newRadius) {
        if (markers && markers.length > 0) {
            for (const marker of markers) {
                if (marker.circle) {
                    marker.circle.setRadius(newRadius);
                }
            }
        }
    }

    async createMarkerByTrack(track, markersGroup, map, storeRoomRadius, showLabel) {
        const marker = leaflet.marker(
            [track.position.coordinates[1], track.position.coordinates[0]]
            , { icon: await this.getIcon(track, showLabel) });
        if (track.thingName) {
            marker.bindTooltip(
                () => { return this.getMCSPopUpByTemplate(track) },
                { direction: 'top', className: 'tooltipClass', permanent: false }
            );
        }
        if (this.isTypeRadious(track.typeKey)) {
            const radiousColor = this.getRadiousColor(track);
            const circle = new leaflet.Circle([track.position.coordinates[1], track.position.coordinates[0]], {
                radius: storeRoomRadius,
                color: radiousColor.color,
                weight: 0.5,
                fillColor: radiousColor.fillColor,
                fillOpacity: 0.5
            }).addTo(map);
            marker.circle = circle;
        }
        markersGroup.addLayer(marker);
        track.show = true;
        marker.trackData = track;
        marker.trackData.stateKey = marker.trackData && marker.trackData.state && marker.trackData.state.key;
        return marker;
    }

    getRadiousColor() {
        return { color: "blue", fillColor: 'black' };
    }

    isTypeRadious(type) {
        return type === 'STOREROOM'
    }

    async getIcon(track, showLabel) {
        let iconFile = null;
        const type = track.typeKey;
        const enterpriseId = track.enterpriseId;
        const directionString = this.getDirectionString(track.direction);
        if (track.iconId) {
            const custom = await mapCurrentStateService.getCustomIcon(track.iconId);
            if (custom && custom.images && custom.images[directionString]) iconFile = this.generateIconData(custom.images[directionString], track, showLabel);
        }
        if (!iconFile && type && enterpriseId) {
            let icon = null
            const custom = await mapCurrentStateService.getCustomIcon(`${enterpriseId}-${type}`);

            if (custom && custom.images && custom.images[directionString]) {
                icon = this.generateIconData(custom.images[directionString], track, showLabel)
            }

            if (icon) iconFile = icon;
        }
        if (!iconFile && type && MARKER_ICONS[type]) {
            const icon = MARKER_ICONS[type];
            track.showBackgroundColor = true;
            iconFile = this.generateIconData(icon, track, showLabel);
        }
        const icon = iconFile ? iconFile : leaflet.icon({ iconUrl: leaflet.Icon.Default, iconSize: [20, 20], iconAnchor: [12, 12] });
        return icon;
    }

    generateIconData(b64Icon, track, showLabel) {
        const stateColor = track.stateColor != null
            ? track.stateColor
            : (track.state != null && track.state.color != null
                ? track.state.color
                : 'none');
        // Este objeto contiene datos que son reemplazados en el template iconSkeleton
        const svgParametersMapped = {
            SVG_ID: `ID-${Math.floor(Math.random() * 10000000)}`,
            BACKGROUND_COLOR: track.showBackgroundColor ? track.color : 'transparent',
            STATE_STYLE: `visibility: visible !important; fill: ${stateColor} !important;`,
            STOPPED_STYLE: !track.stopped ? `visibility: hidden;` : '',
            INNER_ICON: b64Icon
        }

        // Establece como vacios los estilos de las direcciones que no se utilizan (en el caso del mapa de recorrido solo se utiliza la direccion este)
        const directions = ['north', 'south', 'east', 'west', 'northeast', 'northwest', 'southeast', 'southwest']
        directions.forEach(dir => {
            svgParametersMapped[dir] = ''
        })

        // svgParametersMapped["east"] = `visibility: visible !important; !important;`

        // Crea el svg reemplazando los valores del template por los valores generados arriba
        const svg = leaflet.Util.template(iconSkeleton, svgParametersMapped)
        let htmlContent = `<span>${svg}</span>`;
        if (showLabel) {
            htmlContent += `<span class="markerLabel_1">${track.thingName}</span>`
        }
        const icon = leaflet.divIcon({
            className: 'leaflet-data-marker',
            html: htmlContent,
            iconAnchor: [50, 50],
            iconSize: [100, 100],
            popupAnchor: [0, -28]
        })

        return icon;
    }

    async getPositionsByThings(idThings) {
        const result = await mapCurrentStateApi.getTrackDataByThingId(idThings);
        return result;
    }

    getLastCurrentStateData(idThings, from) {
        const result = mapCurrentStateApi.getStatusByCommunicationDate(idThings, from);
        return result;
    }

    async getHarvestFrontStatus(harvestFrontIds) {
        return await mapCurrentStateApi.getHarvestFrontStatus(harvestFrontIds);
    }

    getMCSPopUpByTemplate(track) {
        const lang = "es";
        let stateName = null;
        if (track.stateName != null && track.stateName != null && track.stateName[lang] != null) {
            stateName = track.stateName[lang];
        } else if (track.state != null && track.state.name != null && track.state.name[lang] != null) {
            stateName = track.state.name[lang];
        }
        const stateColor = track.stateColor != null
            ? track.stateColor
            : (track.state != null && track.state.color != null
                ? track.state.color
                : 'none');
        const timezone = -3;
        let communicationTimestampHtml = '';
        if (track.communicationTimestamp != null) {
            const comunicationTimestampConversion = track.communicationTimestamp != null
                ? ReportConversionUtil.applyTimezone(track.communicationTimestamp, timezone)
                : null;
            const comunicationTimestamp = comunicationTimestampConversion ?
                comunicationTimestampConversion.dateString + ' ' + comunicationTimestampConversion.timeString : null;
            const comunicationTimestampColor = this.getTimestampColor(track.communicationTimestamp);
            const comunicationTimestampName = i18n.t('mapReport.COMUNICATION_DATE', { lang });
            communicationTimestampHtml = `${comunicationTimestampName}: <span style='color: ${comunicationTimestampColor} !important;'>${comunicationTimestamp}</span>`;
        }

        let positionTimestampHtml = '';
        if (track.trackTimestamp != null) {
            const positionTimestampConversion = ReportConversionUtil.applyTimezone(track.trackTimestamp, timezone);
            const positionTimestamp = positionTimestampConversion.dateString + ' ' + positionTimestampConversion.timeString;
            const positionTimestampColor = this.getTimestampColor(track.trackTimestamp);
            const positionTimestampName = i18n.t('mapReport.POSITION_DATE', { lang });
            positionTimestampHtml = `${positionTimestampName}: <span style='color: ${positionTimestampColor} !important;'>${positionTimestamp}</span>`;
        }

        const engineState = track.engineRunning === true ?
            i18n.t('mapReport.ENGINE_STATE.ON', { lang }) :
            (track.engineRunning === false ? i18n.t('mapReport.ENGINE_STATE.OFF', { lang }) : undefined);
        const engineStateColor = track.engineRunning === true ? '#66BB6A' : '#263238';
        const stateMapMarker = stateName != null
            ? `<span style='color: ${stateColor} !important; text-transform: uppercase!important;'>[${stateName}]</span>`
            : '';
        const engineStateHTML = engineState != null
            ? `<span style='color: ${engineStateColor} !important;'>${i18n.t('mapReport.ENGINE', { lang })} ${engineState}</span><br>`
            : '';
        const speedAndDirection = this.speedDetentionToStringTranslated(
            track.speed, track.direction, track.stopped, track.stoppedTime, track.trackTimestamp, lang, true);
        const rpm = track.rpm != null
            ? `<span>${track.rpm} RPM</span><br>`
            : '';

        const harvestSpeedTextColor = '#263238'
        const driveUnitHTML = `<span style='color: ${harvestSpeedTextColor} !important;'>${i18n.t('mapReport.DRIVE_UNIT', { lang })}${track.driveUnitName ? track.driveUnitName : ''}</span><br>`
        const referenceSpeedHTML = `<span style='color: ${harvestSpeedTextColor} !important;'>${i18n.t('mapReport.REFERENCE_SPEED', { lang })} ${track.referenceSpeed || 0}</span><br>`
        const tonPerHourHTML = `<span style='color: ${harvestSpeedTextColor} !important;'>${i18n.t('mapReport.TONS_PER_HOUR', { lang })} ${track.tonsPerHour || 0}</span><br>`

        return `<h4>${track.thingName} ${stateMapMarker}</h4>
            <hr>
            ${engineStateHTML}
            ${speedAndDirection.translation}
            <br>
            ${rpm}
            ${positionTimestampHtml}
            <br>
            ${communicationTimestampHtml}
            <br>
            ${driveUnitHTML}
            ${referenceSpeedHTML}
            ${tonPerHourHTML}
            `
    }

    getTimestampColor(timestamp) {
        if (!timestamp) { return '#263238'; }
        const nowSubstract10 = moment().utc().subtract(10, 'minutes');
        const nowSubstract60 = moment().utc().subtract(60, 'minutes');
        const momentTimestamp = moment.unix(timestamp);
        if (momentTimestamp.isSameOrAfter(nowSubstract10)) {
            return '#66BB6A';
        } else if (momentTimestamp.isSameOrAfter(nowSubstract60) &&
            momentTimestamp.isBefore(nowSubstract10)) {
            return '#d6c105';
        } else if (momentTimestamp.isBefore(nowSubstract60)) {
            return '#ef5350';
        } else {
            return '#263238';
        }
    }

    speedDetentionToStringTranslated(
        speed, direction, stopped, stoppedTime, trackTimestamp, lang, returnDirectionStr = false) {
        direction = Number(direction);
        stoppedTime = Number(stoppedTime);
        speed = Number(speed);

        let directionStr = null;
        let cardinalPoint = '';
        if (direction === 0) {
            cardinalPoint = i18n.t('utils.NORTH', { lang });
            directionStr = 'north';
        } else if (direction === 90) {
            cardinalPoint = i18n.t('utils.EAST', { lang });
            directionStr = 'east';
        } else if (direction === 180) {
            cardinalPoint = i18n.t('utils.SOUTH', { lang });
            directionStr = 'south';
        } else if (direction === 270) {
            cardinalPoint = i18n.t('utils.WEST', { lang });
            directionStr = 'west';
        } else if (direction > 0 && direction < 90) {
            cardinalPoint = i18n.t('utils.NORTH_EAST', { lang });
            directionStr = 'northeast';
        } else if (direction > 90 && direction < 180) {
            cardinalPoint = i18n.t('utils.SOUTH_EAST', { lang });
            directionStr = 'southeast';
        } else if (direction > 180 && direction < 270) {
            cardinalPoint = i18n.t('utils.SOUTH_WEST', { lang });
            directionStr = 'southwest';
        } else if (direction > 270 && direction < 360) {
            cardinalPoint = i18n.t('utils.NORTH_WEST', { lang });
            directionStr = 'northwest';
        }

        let translation = '';
        if (stopped && (stoppedTime || stoppedTime === 0)) {
            const stoppedTimeConversion = ReportConversionUtil.secondsToString(stoppedTime + (moment().utc().unix() - trackTimestamp));
            translation = i18n.t('utils.STOPPED_SINCE', { lang }) + ' ' + stoppedTimeConversion;
        } else if (!stopped && (speed || speed === 0)) {
            if (cardinalPoint.length > 0) {
                translation = speed.toFixed(2) + ' ' + i18n.t('utils.KMH_TO', { lang }) + ' ' + cardinalPoint;
            } else {
                translation = speed.toFixed(2) + ' ' + i18n.t('utils.KMH', { lang });
            }
        } else {
            translation = '';
        }
        return returnDirectionStr
            ? { translation, directionStr }
            : translation;
    }

    getDirectionString(direction) {
        let directionStr = "east";
        if (direction === 0) {
            directionStr = 'north';
        } else if (direction === 90) {
            directionStr = 'east';
        } else if (direction === 180) {
            directionStr = 'south';
        } else if (direction === 270) {
            directionStr = 'west';
        } else if (direction > 0 && direction < 90) {
            directionStr = 'northeast';
        } else if (direction > 90 && direction < 180) {
            directionStr = 'southeast';
        } else if (direction > 180 && direction < 270) {
            directionStr = 'southwest';
        } else if (direction > 270 && direction < 360) {
            directionStr = 'northwest';
        }
        return directionStr;
    }

    filterMarkersByTrackData(filters, map, markersGroup, markers) {
        Array.from(markers.values()).forEach((layer) => {
            if (layer.trackData) {
                const previousShow = layer.trackData.show;
                const show = this.showByFilters(filters, layer.trackData);
                this.addShowMarker(markersGroup, map, layer, show, previousShow);

            }
        });
    }

    filterPointOfInterestAndZones(map, pointOfInterests, zones, filters) {
        if (pointOfInterests && pointOfInterests.length > 0) {
            for (const pointOfInterest of pointOfInterests) {
                const previousShow = pointOfInterest.pointOfInterestData.show;
                const show = this.showByFilters(filters, pointOfInterest.pointOfInterestData);
                if (!show) {
                    map.removeLayer(pointOfInterest);
                } else {
                    if (!previousShow) {
                        map.addLayer(pointOfInterest);
                    }
                }
            }
        }
        if (zones && zones.length > 0) {
            for (const zone of zones) {
                const previousShow = zone.data.show;
                const show = this.showByFilters(filters, zone.data);
                if (!show) {
                    map.removeLayer(zone);
                } else {
                    if (!previousShow) {
                        map.addLayer(zone);
                    }
                }
            }
        }
    }

    addShowMarker(markersGroup, map, marker, show, previousShow) {
        if (!show) {
            markersGroup.removeLayer(marker);
            if (marker.circle) {
                map.removeLayer(marker.circle);
            }
        } else {
            if (!previousShow) {
                markersGroup.addLayer(marker);
                if (marker.circle) {
                    map.addLayer(marker.circle);
                }
            }
        }
    }

    showByFilters(filters, data) {
        data.show = true;
        if (filters && filters.length > 0) {
            for (const filter of filters) {
                const fieldValue = data[filter.field];
                if (!filter.conditions.get(fieldValue) && (fieldValue || !filter.nulable)) {
                    data.show = false;
                    return false;
                }
            }
        }
        return true;
    }

    initLayers(mapTiles, layers, map) {
        let baseTile = null
        const currentLayer = (mapTiles.find(mapTile => mapTile.base) || {}).name;
        let finalLayer = null;
        mapTiles.forEach((mapTile, index) => {
            const layer = leaflet.tileLayer(mapTile.url, {
                tileSize: 256,
                zoomOffset: 0,
                minZoom: 1,
                maxZoom: 21,
                attribution: mapTile.attribution,
                crossOrigin: true
            })
            layers[mapTile.name] = layer

            if (mapTile.name === currentLayer || index === 0) {
                baseTile = layer
                finalLayer = mapTile.name
            }
        })
        baseTile.addTo(map);
        return { currentLayer: finalLayer };
    }

    async showHideZonesByEnteprise(show, map, zonesMakerMap) {
        if (zonesMakerMap.size === 0) {
            const zones = await ZonesApi.getZonesByEnterprise();
            const zonesProcessed = this.processZones(zones);
            this.addPolygons(map, zonesMakerMap, zonesProcessed);
        } else {
            if (show) {
                Array.from(zonesMakerMap.values()).forEach(zoneLayer => {
                    if (zoneLayer.data.show) {
                        map.addLayer(zoneLayer);
                    }
                });
            } else {
                Array.from(zonesMakerMap.values()).forEach(zoneLayer => {
                    map.removeLayer(zoneLayer);
                });
            }
        }
    }

    updateMaxClusterRadius(map, markers, markersGroup, maxValue) {
        map.removeLayer(markersGroup);
        const newMarkersGroup = leaflet.markerClusterGroup({
            showCoverageOnHover: false,
            maxClusterRadius: maxValue,
            spiderfyDistanceMultiplier: 3,
            disableClusteringAtZoom: 16,
            chunkedLoading: false
        });
        newMarkersGroup.addTo(map);
        newMarkersGroup.addLayers(markers);
        return newMarkersGroup;
    }

    setPermanentToolTipsByTrackName(markers, showLabels) {
        for (const marker of markers) {
            // marker.unbindTooltip();
            marker.bindTooltip(marker.trackData.thingName || "", { className: "toolTips", direction: "right", permanent: showLabels });
        }
    }

    async updateIconsOfMarkers(markers, showLabel) {
        if (markers && markers.length > 0) {
            for (const marker of markers) {
                const icon = await this.getIcon(marker.trackData, showLabel);
                marker.setIcon(icon);
            }
        }
    }

    async getUpdatedIcons() {
        try {
            const currentIcons = await indexedDBService.getAll('svgIcons')
            const response = await thingApi.getUpdatedIcons(currentIcons.map(element => ({ id: element.id, version: element.version })))
            if (response.data != null && response.data.length > 0) {
                for (const icon of response.data) {
                    if (icon.deleted) {
                        await indexedDBService.deleteOneById('svgIcons', icon.uiId)
                    } else {
                        await indexedDBService.put('svgIcons', icon)
                    }
                }
                console.info('Custom map icons updated successfully')
            }
        } catch (error) {
            console.error(error)
            return null
        }
    }

    async getAllPointOfInterestGroup() {
        const pointOfInterestList = [];
        const pointOfInterests = await pointOfInterestApi.getAllByInterprise();
        const pointOfInterestMap = await filterService.getAllCategoriesMapByEnterprise();
        if (pointOfInterests && pointOfInterests.length > 0) {
            for (const pointOfInterest of pointOfInterests) {
                const category = pointOfInterestMap.get(pointOfInterest.categoryId);
                const circle = new leaflet.circle([pointOfInterest.center.coordinates[1], pointOfInterest.center.coordinates[0]], {
                    radius: pointOfInterest.radius,
                    color: '#3388FF',
                    weight: 5,
                    fillColor: '#3388FF90',
                    fillOpacity: 0.5
                });
                circle.bindTooltip(pointOfInterest.name || "", { className: "toolTips", direction: "right", permanent: false });
                circle.pointOfInterestData = pointOfInterest;
                circle.pointOfInterestData.category = circle.pointOfInterestData.categoryId;
                if (category && category.logo) {
                    const icon = await this.getIconBase64(category);
                    const marker = leaflet.marker(
                        [pointOfInterest.center.coordinates[1], pointOfInterest.center.coordinates[0]],
                        { icon: icon }
                    );
                    // marker.bindTooltip(pointOfInterest.name || "", { className: "toolTips", direction: "right", permanent: false });
                    marker.pointOfInterestData = pointOfInterest;
                    marker.pointOfInterestData.category = marker.pointOfInterestData.categoryId;
                    pointOfInterestList.push(marker);
                }
                pointOfInterestList.push(circle);
            }
        }
        return pointOfInterestList;
    }

    async getIconBase64(iconData) {
        const icon = leaflet.divIcon({
            className: 'leaflet-data-marker',
            html: `<span><img width="${iconData.size ? iconData.size : 20}" height="${iconData.size ? iconData.size : 20}" src="${'data:image/jpeg;base64,' + iconData.logo}"></img></span>`,
            iconAnchor: [12, 12],
            iconSize: iconData.size ? iconData.size : [20, 20]
        });
        return icon;
    }

    async showHidePointOfInterest(map, show, pointsOfInterestMap) {
        if (Array.from(pointsOfInterestMap.values()).length === 0) {
            const result = await this.getAllPointOfInterestGroup();
            for (const marker of result) {
                if (pointsOfInterestMap.get(marker.pointOfInterestData._id)) {
                    pointsOfInterestMap.set(marker.pointOfInterestData._id + "1", marker);
                } else {
                    pointsOfInterestMap.set(marker.pointOfInterestData._id, marker);
                }
            }
        }
        const pointsOfInterests = Array.from(pointsOfInterestMap.values());
        if (show) {
            for (const pointOfInterest of pointsOfInterests) {
                map.addLayer(pointOfInterest);
            }
        } else {
            for (const pointOfInterest of pointsOfInterests) {
                map.removeLayer(pointOfInterest);
            }
        }
    }
}

export default new MapService();