import stateApi from "../api/state.api";

class StateService {

    async getStatesNameMapByThingsId(thingsId, field) {
        const states = await stateApi.getStatesByThingsFilterStructure(thingsId);
        const data = states.data;
        const statesMap = {};
        data.forEach(state => {
            statesMap[state[field]] = state.name
        })
        return statesMap;
    }

    async getStatesTypesByEnterpriseId () {
        return await stateApi.getStatesTypesByEnterpriseId();
    }

    async getStatesTypeByKey (stateTypeKeys) {
        return await stateApi.getStatesTypeByKey(stateTypeKeys)
    }

}

export default new StateService();