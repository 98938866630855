import BULLDOZER from './BULLDOZER'
import CART from './CART'
import EXCAVATOR from './EXCAVATOR'
import FARM_OTHER_ACTIVITIES from './FARM_OTHER_ACTIVITIES'
import FRONT_LOADER from './FRONT_LOADER'
import GRADER from './GRADER'
import HARVESTER from './HARVESTER'
import HARVEST_FRONT from './HARVEST_FRONT'
import { default as LOGISTIC_TRUCK, default as TRUCK } from './LOGISTIC_TRUCK'
import LOGISTIC_VEHICLE from './LOGISTIC_VEHICLE'
import QUAD from './QUAD'
import TRACTOR from './TRACTOR'
import TRAILER from './TRAILER'

export const MARKER_ICONS = {
    BULLDOZER,
    EXCAVATOR,
    FRONT_LOADER,
    GRADER,
    HARVESTER,
    QUAD,
    TRACTOR,
    TRAILER,
    TRUCK,
    LOGISTIC_TRUCK,
    FARM_OTHER_ACTIVITIES,
    LOGISTIC_VEHICLE,
    CART,
    HARVEST_FRONT
}