import leaflet from 'leaflet';
import mapReportApi from '../api/mapReport.api';
import mapService from './mapService';
import { parseDateByTimezone } from '../tools/functions';

class MapHistoryService {

    centerMap(idThing, makers, zoom, map) {
        const marker = makers[idThing];
        map.setView(marker.getLatLng(), zoom);
    }

    async getTrackData(things, from) {
        const result = await mapReportApi.historyTracks(things, from, 24);
        return result;
    }

    async createMarker(track, map, storeRoomRadius, statesMap) {
        this.statesMap = statesMap;
        const enterpriseId = JSON.parse(localStorage.getItem('enterpriseId'))
        const marker = leaflet.marker(
            [track.position[1], track.position[0]],
            {
                icon: await mapService.getIcon(
                    { typeKey: track.type, enterpriseId: enterpriseId }
                )
            });
        marker.bindTooltip(this.getToolTipThing(track));
        map.addLayer(marker);
        if (track.type === 'STOREROOM') {
            const circle = new leaflet.Circle([track.position[1], track.position[0]], {
                radius: storeRoomRadius,
                color: 'blue',
                weight: 0.5,
                fillColor: 'black',
                fillOpacity: 0.5
            }).addTo(map);
            marker.circle = circle;
        }
        return marker;
    }

    getToolTipThing(track) {
        let tooltip = track.name;
        const stateId = track.state && track.state.type && track.state.type._id;
        const stateName = this.statesMap[stateId];
        if (stateName) {
            tooltip += `<br>Estado: ${stateName}`
        }
        tooltip += `<br>Fecha Trama: ${parseDateByTimezone(track.trackTimestamp)}`;
        tooltip += `<br>Fecha Comunicación: ${parseDateByTimezone(track.communicationTimestamp)}`;
        return tooltip;
    }

    async updateLatLong(track, marker) {
        const newLatLng = new leaflet.LatLng(track.position[1], track.position[0]);
        const tooltip = this.getToolTipThing(track);
        marker.setLatLng(newLatLng);
        marker.setTooltipContent(tooltip);
        if (marker.circle) {
            marker.circle.setLatLng(newLatLng);
        }
    }

    completeEmptyMap(previousTime, currentTime, linetimeMap, data) {
        if (data) {
            for (let i = previousTime + 1; i <= currentTime; i++) {
                if (linetimeMap.get(i)) {
                    linetimeMap.get(i).push(data);
                } else {
                    linetimeMap.set(i, [data]);
                }
            }
        }
    }

    // crear "trama anterior"
    async getLineTimeMap(tracks, map, timestart, storeRoomRadius, statesMap, communicationDateSort) {
        const linetimeMap = new Map();
        const markersMap = {};
        let interval = null;
        if (tracks && tracks.length > 0) {
            for (const thingTracks of tracks) {
                if (thingTracks.positions && thingTracks.positions.length > 0) {
                    const marker = await this.createMarker(
                        {
                            name: thingTracks.name, type: thingTracks.type,
                            position: thingTracks.positions[0].position, state: thingTracks.positions[0].state,
                            communicationTimestamp: thingTracks.positions[0].communicationTimestamp,
                            trackTimestamp: thingTracks.positions[0].trackTimestamp
                        },
                        map, storeRoomRadius, statesMap
                    );
                    markersMap[thingTracks.idThing] = marker;
                    let previousTime = timestart;
                    let previousData = null;
                    let timeString = null;
                    for (const position of thingTracks.positions) {
                        timeString = null;
                        if (communicationDateSort) {
                            timeString = position.communicationTimestamp;
                        } else {
                            timeString = position.trackTimestamp;
                        }
                        const data = {
                            idThing: thingTracks.idThing, position: position.position,
                            communicationTimestamp: position.communicationTimestamp,
                            trackTimestamp: position.trackTimestamp,
                            name: thingTracks.name,
                            state: position.state
                        };
                        this.completeEmptyMap(previousTime, timeString, linetimeMap, previousData);
                        previousTime = timeString;
                        previousData = data;
                    }
                    this.completeEmptyMap(previousTime, timeString + 1, linetimeMap, previousData);
                }
            }
        }
        return { linetimeMap, markersMap, interval };
    }

    async processMomentTracks(tracksTime, timestamp, markersMap) {
        const things = tracksTime.get(timestamp);
        if (things && things.length > 0) {
            for (const thing of things) {
                const marker = markersMap[thing.idThing];
                if (marker) {
                    await this.updateLatLong(thing, marker);
                }
            }
        }
    }
}

export default new MapHistoryService();